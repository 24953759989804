import React from "react";
import "./managed-packages.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const ManagedPackages = () => {
  const data = useStaticQuery(graphql`
    query {
      fullStackDevMain: file(relativePath: { eq: "full-stack-dev-main.png" }) {
        publicURL
      },
      fullStackDevYellowCircle: file(relativePath: { eq: "full-stack-dev-yellow-circle.svg" }) {
        publicURL
      },
      fullStackDevHalfCircle: file(relativePath: { eq: "full-stack-dev-half-circle.svg" }) {
        publicURL
      },
      fullStackDevTwoCircles: file(relativePath: { eq: "full-stack-dev-two-circles.svg" }) {
        publicURL
      },
      fullStackDevCurvyBg: file(relativePath: { eq: "full-stack-dev-curvy-bg.png" }) {
        publicURL
      },
      fullStackDevTwoTriangles: file(relativePath: { eq: "full-stack-dev-two-triangles.svg" }) {
        publicURL
      },
      fullStackDevYellowGrid: file(relativePath: { eq: "full-stack-dev-yellow-grid.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="salesforce-product-content">
      <div className="salesforce-product-content-left">
        <img alt="" className="content-left-img" src={data.fullStackDevMain.publicURL}/>
        <img alt="" className="content-left-yellow-circle" src={data.fullStackDevYellowCircle.publicURL}/>
        <img alt="" className="content-left-half-cirle" src={data.fullStackDevHalfCircle.publicURL}/>
        <img alt="" className="content-left-two-circles" src={data.fullStackDevTwoCircles.publicURL}/>
        <img alt="" className="content-left-curvy-bg" src={data.fullStackDevCurvyBg.publicURL}/>
        <img alt="" className="content-left-two-triangles" src={data.fullStackDevTwoTriangles.publicURL}/>
        <div className="content-left-yellow-circle-two"></div>
        <img alt="" className="content-left-yellow-grid" src={data.fullStackDevYellowGrid.publicURL}/>
      </div>
      <div className="salesforce-product-content-right">
        <h3 className="salesforce-product-content-title">Manages Packages</h3>
        <p className="salesforce-product-content-text">Distributing applications through Appexchange can be overwhelming. First, there the question of what to package. Next, there's the security review that comes with its own set of challenges. And finally, there's licesense management process to worry about. 
        Our team has built a vast number of managed packages that are live on Appexchange and we know the process inside out. Let us help you getting your product to market.
        </p>
        <Link to="/#contact" className="yellow-btn">Start Packaging</Link>
        {/* <button className="salesforce-product-content-btn">
              <img className="salesforce-product-content-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-product-content-btn-text">Learn more</p>
            </button> */}
      </div>
    </div>
  )
};

export default ManagedPackages
