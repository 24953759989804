import React from "react";
import "./security-assessments.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const SecurityAssessments = () => {
  const data = useStaticQuery(graphql`
    query {
      designArchitectureMain: file(relativePath: { eq: "design-architecture-main.png" }) {
        publicURL
      },
      designArchitectureTwoCircles: file(relativePath: { eq: "design-architecture-two-circles.svg" }) {
        publicURL
      },
      designArchitectureCurvyBg: file(relativePath: { eq: "design-architecture-curvy-bg.png" }) {
        publicURL
      },
      designArchitectureTrianglesCircle: file(relativePath: { eq: "design-architecture-triangles-circle.svg" }) {
        publicURL
      },
      designArchitectureGridImg: file(relativePath: { eq: "design-architecture-grid-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting-content-section">
      <div className="salesforce-consulting-content-section-left">
        <h3 className="salesforce-consulting-content-section-title">Security Assessments</h3>
        <p className="salesforce-consulting-content-section-text">In any enterprise environment security is paramount. 
        It is often neglected until it's too late. Phishing scams, malware and social engineering are all too common these days to ignore. 
        There is no better time to start securing your Salesforce environment than now. 
        Unfortunately there are little out-of-the-box capabilities that can help assess the security posture of your org. 
        Our team has vast experience in this area and can guide your administrators towards setting up the right access policies.
        </p>
        <Link to="/#contact" className="yellow-btn">Learn More</Link>
        {/* <button className="salesforce-consulting-content-btn">
              <img alt="" className="salesforce-consulting-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-consulting-btn-text">Learn more</p>
            </button> */}
      </div>
      <div className="salesforce-consulting-content-section-right">
        <img alt="" className="design-architecture-main" src={data.designArchitectureMain.publicURL}/>
        <div className="design-architerture-little-circle"></div>
        <img alt="" className="design-architerture-two-circles" src={data.designArchitectureTwoCircles.publicURL}/>
        <div className="design-architerture-yellow-border"></div>
        <img alt="" className="design-architerture-curvy-bg" src={data.designArchitectureCurvyBg.publicURL}/>
        <img alt="" className="design-architerture-triangles-circle" src={data.designArchitectureTrianglesCircle.publicURL}/>
        <img alt="" className="design-architerture-grid-img" src={data.designArchitectureGridImg.publicURL}/>
        {/* <img alt="" src={data.designAndArchitectureImg.publicURL}/> */}
      </div>
    </div>
  )
};

export default SecurityAssessments
