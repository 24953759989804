import React from "react";
import "./design-and-architecture.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const DesignAndArchitecture = () => {
  const data = useStaticQuery(graphql`
    query {
      designArchitectureMain: file(relativePath: { eq: "design-architecture-main.png" }) {
        publicURL
      },
      designArchitectureTwoCircles: file(relativePath: { eq: "design-architecture-two-circles.svg" }) {
        publicURL
      },
      designArchitectureCurvyBg: file(relativePath: { eq: "design-architecture-curvy-bg.png" }) {
        publicURL
      },
      designArchitectureTrianglesCircle: file(relativePath: { eq: "design-architecture-triangles-circle.svg" }) {
        publicURL
      },
      designArchitectureGridImg: file(relativePath: { eq: "design-architecture-grid-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting-content-section">
      <div className="salesforce-consulting-content-section-left">
        <h3 className="salesforce-consulting-content-section-title">Design and Architecture</h3>
        <p className="salesforce-consulting-content-section-text">Now that you have identified the need to make changes to an existing process, the next step is plan and strategize. Laying a solid foundation early on will help during execution and go-live. It will also help level set with all the stakeholders involved. There is plenty to think about from functionality to the nitty gritty details of the architecture, our team can assist every step of the way. We can provide blueprints that you can take anywhere and have confidence that the result will be what you expect.
        </p>
        <Link to="/#contact" className="yellow-btn">Start Planning</Link>
        {/* <button className="salesforce-consulting-content-btn">
              <img alt="" className="salesforce-consulting-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-consulting-btn-text">Learn more</p>
            </button> */}
      </div>
      <div className="salesforce-consulting-content-section-right">
        <img alt="" className="design-architecture-main" src={data.designArchitectureMain.publicURL}/>
        <div className="design-architerture-little-circle"></div>
        <img alt="" className="design-architerture-two-circles" src={data.designArchitectureTwoCircles.publicURL}/>
        <div className="design-architerture-yellow-border"></div>
        <img alt="" className="design-architerture-curvy-bg" src={data.designArchitectureCurvyBg.publicURL}/>
        <img alt="" className="design-architerture-triangles-circle" src={data.designArchitectureTrianglesCircle.publicURL}/>
        <img alt="" className="design-architerture-grid-img" src={data.designArchitectureGridImg.publicURL}/>
        {/* <img alt="" src={data.designAndArchitectureImg.publicURL}/> */}
      </div>
    </div>
  )
};

export default DesignAndArchitecture
