import React from "react";
import "./cpq-applications.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const CpqApplications = () => {
  const data = useStaticQuery(graphql`
    query {
      fullStackDevMain: file(relativePath: { eq: "full-stack-dev-main.png" }) {
        publicURL
      },
      fullStackDevYellowCircle: file(relativePath: { eq: "full-stack-dev-yellow-circle.svg" }) {
        publicURL
      },
      fullStackDevHalfCircle: file(relativePath: { eq: "full-stack-dev-half-circle.svg" }) {
        publicURL
      },
      fullStackDevTwoCircles: file(relativePath: { eq: "full-stack-dev-two-circles.svg" }) {
        publicURL
      },
      fullStackDevCurvyBg: file(relativePath: { eq: "full-stack-dev-curvy-bg.png" }) {
        publicURL
      },
      fullStackDevTwoTriangles: file(relativePath: { eq: "full-stack-dev-two-triangles.svg" }) {
        publicURL
      },
      fullStackDevYellowGrid: file(relativePath: { eq: "full-stack-dev-yellow-grid.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="salesforce-product-content">
      <div className="salesforce-product-content-left">
        <img alt="" className="content-left-img" src={data.fullStackDevMain.publicURL}/>
        <img alt="" className="content-left-yellow-circle" src={data.fullStackDevYellowCircle.publicURL}/>
        <img alt="" className="content-left-half-cirle" src={data.fullStackDevHalfCircle.publicURL}/>
        <img alt="" className="content-left-two-circles" src={data.fullStackDevTwoCircles.publicURL}/>
        <img alt="" className="content-left-curvy-bg" src={data.fullStackDevCurvyBg.publicURL}/>
        <img alt="" className="content-left-two-triangles" src={data.fullStackDevTwoTriangles.publicURL}/>
        <div className="content-left-yellow-circle-two"></div>
        <img alt="" className="content-left-yellow-grid" src={data.fullStackDevYellowGrid.publicURL}/>
      </div>
      <div className="salesforce-product-content-right">
        <h3 className="salesforce-product-content-title">CPQ Applications</h3>
        <p className="salesforce-product-content-text">CPQ happens to be our specialty when it comes to building custom products. We've built native and non-native CPQ systems. We've built rule-based and attribute based configurators, custom product catalogs and full quote-to-cash solutions. Can't find a product that address your needs? Or perhaps you have a killer CPQ app in mind that you'd like to go to market with? Reach out to our team to start building!  
        </p>
        <Link to="/#contact" className="yellow-btn">Start Building</Link>
        {/* <button className="salesforce-product-content-btn">
              <img alt="" className="salesforce-product-content-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-product-content-btn-text">Learn more</p>
            </button> */}
      </div>
    </div>
  )
};

export default CpqApplications
