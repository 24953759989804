import React from "react";
import "./intro.scss";
import { graphql, useStaticQuery } from "gatsby";

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      servicesImg: file(relativePath: { eq: "services-page-intro-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="services-container container">
      <div className="services-left"
           data-sal="slide-right"

           data-sal-easing="ease-in-cubic"
           data-sal-duration="1000"
      >
        <div className="services-left-title">Services</div>
        <div className="services-left-text">
          <p>Congradulations on selecting Salesforce, the best business platform there is! 
            We are here to help you make the most of your investment. 
            Whether you are looking for an implementation partner or to extend existing functionality, our team has done it all.
            No matter the project, we are always committed to your success. 
          </p>
        </div>
      </div>
      <div className="services-right">
        <img alt="" className="services-img" src={data.servicesImg.publicURL}/>
      </div>
    </div>
  )
};

export default Intro
