import React from "react";
import "./mobile-app-development.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const MobileAppDevelopment = () => {
  const data = useStaticQuery(graphql`
    query {
      fullStackDevMain: file(relativePath: { eq: "full-stack-dev-main.png" }) {
        publicURL
      },
      fullStackDevYellowCircle: file(relativePath: { eq: "full-stack-dev-yellow-circle.svg" }) {
        publicURL
      },
      fullStackDevHalfCircle: file(relativePath: { eq: "full-stack-dev-half-circle.svg" }) {
        publicURL
      },
      fullStackDevTwoCircles: file(relativePath: { eq: "full-stack-dev-two-circles.svg" }) {
        publicURL
      },
      fullStackDevCurvyBg: file(relativePath: { eq: "full-stack-dev-curvy-bg.png" }) {
        publicURL
      },
      fullStackDevTwoTriangles: file(relativePath: { eq: "full-stack-dev-two-triangles.svg" }) {
        publicURL
      },
      fullStackDevYellowGrid: file(relativePath: { eq: "full-stack-dev-yellow-grid.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="salesforce-product-content">
      <div className="salesforce-product-content-left">
        <img alt="" className="content-left-img" src={data.fullStackDevMain.publicURL}/>
        <img alt="" className="content-left-yellow-circle" src={data.fullStackDevYellowCircle.publicURL}/>
        <img alt="" className="content-left-half-cirle" src={data.fullStackDevHalfCircle.publicURL}/>
        <img alt="" className="content-left-two-circles" src={data.fullStackDevTwoCircles.publicURL}/>
        <img alt="" className="content-left-curvy-bg" src={data.fullStackDevCurvyBg.publicURL}/>
        <img alt="" className="content-left-two-triangles" src={data.fullStackDevTwoTriangles.publicURL}/>
        <div className="content-left-yellow-circle-two"></div>
        <img alt="" className="content-left-yellow-grid" src={data.fullStackDevYellowGrid.publicURL}/>
      </div>
      <div className="salesforce-product-content-right">
        <h3 className="salesforce-product-content-title">Mobile App Development</h3>
        <p className="salesforce-product-content-text">In this day and age if an application is not mobile ready it might as well not exist at all. With very few exceptions, every app needs a mobile component. Taking a step into the mobile apps world can be daunting - there are the mobile ready web applications, hybrid apps, iOS and Androind native apps. On top of that there's the need to constantly keep up with OS and device vendors and their updates. 
        Our team can help you navigate the madness of mobile development and ensure your app is always functional on any device.
        </p>
        <Link to="/#contact" className="yellow-btn">Go Mobile</Link>
        {/* <button className="salesforce-product-content-btn">
              <img alt="" className="salesforce-product-content-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-product-content-btn-text">Learn more</p>
            </button> */}
      </div>
    </div>
  )
};

export default MobileAppDevelopment
