import React from "react";
import "./salesforce-cpq-implementations.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const SalesforceCpqImplementations = () => {
  const data = useStaticQuery(graphql`
    query {
      designArchitectureMain: file(relativePath: { eq: "design-architecture-main.png" }) {
        publicURL
      },
      designArchitectureTwoCircles: file(relativePath: { eq: "design-architecture-two-circles.svg" }) {
        publicURL
      },
      designArchitectureCurvyBg: file(relativePath: { eq: "design-architecture-curvy-bg.png" }) {
        publicURL
      },
      designArchitectureTrianglesCircle: file(relativePath: { eq: "design-architecture-triangles-circle.svg" }) {
        publicURL
      },
      designArchitectureGridImg: file(relativePath: { eq: "design-architecture-grid-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting-content-section">
      <div className="salesforce-consulting-content-section-left">
        <h3 className="salesforce-consulting-content-section-title">Salesforce CQP Implementations</h3>
        <p className="salesforce-consulting-content-section-text">Salesforce CPQ is a fantastic and flexible tool capable of addressing even the most challenging sales use cases.
        Backed by Salesforce, it is usually a no brainer for most companies when it comes to selecting their CPQ vendor. 
        While most of the customizations are declarative in nature there is still a need for expert guidance. 
        Due to complexity, multitude of features and data migration needs most enterprises are working with implementation partners on their CPQ projects. Allow us to help you navigate the CPQ world and close more deals faster.
        </p>
        <Link to="/#contact" className="yellow-btn">Start Configuring</Link>
        {/* <button className="salesforce-consulting-content-btn">
              <img alt="" className="salesforce-consulting-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-consulting-btn-text">Learn more</p>
            </button> */}
      </div>
      <div className="salesforce-consulting-content-section-right">
        <img alt="" className="design-architecture-main" src={data.designArchitectureMain.publicURL}/>
        <div className="design-architerture-little-circle"></div>
        <img alt="" className="design-architerture-two-circles" src={data.designArchitectureTwoCircles.publicURL}/>
        <div className="design-architerture-yellow-border"></div>
        <img alt="" className="design-architerture-curvy-bg" src={data.designArchitectureCurvyBg.publicURL}/>
        <img alt="" className="design-architerture-triangles-circle" src={data.designArchitectureTrianglesCircle.publicURL}/>
        <img alt="" className="design-architerture-grid-img" src={data.designArchitectureGridImg.publicURL}/>
        {/* <img alt="" src={data.designAndArchitectureImg.publicURL}/> */}
      </div>
    </div>
  )
};

export default SalesforceCpqImplementations
