import React from "react";
import "./salesforce-consulting-first.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const SalesforceConsultingFirst = () => {
  const data = useStaticQuery(graphql`
    query {
      salesforceConsulting: file(relativePath: { eq: "salesforce-consulting.svg" }) {
        publicURL
      },
      digitalProductDevelopmentImg: file(relativePath: { eq: "product-development-2.svg" }) {
        publicURL
      },
      salesforceConsultingIcon: file(relativePath: { eq: "salesforce-consulting-img-2.svg" }) {
        publicURL
      },
      salesforceConsultingNext: file(relativePath: { eq: "salesforce-consulting-next.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting">
      <img alt="" src={data.salesforceConsulting.publicURL}/>
      <div className="salesforce-consulting-content">
        <div className="content-left content"
             data-sal="zoom-in"
             data-sal-delay="400"
             data-sal-easing="ease"
             data-sal-duration="1000"
        >
          <img alt="" src={data.digitalProductDevelopmentImg.publicURL}/>
          <h3>Salesforce and Digital Product Development</h3>
          <p>Working on a new idea to bring to life and distribute on the market? Or perhaps thinking about an application just for your team? Let us help you bring your ideas to life. From design to go-live or team will be there every step of the way.</p>
          <Link to="#salesforce-product" ><img alt="" className="arrow-link" src={data.salesforceConsultingNext.publicURL}/></Link>
        </div>
        <div className="content-right content"
             data-sal="zoom-in"
             data-sal-delay="800"
             data-sal-easing="ease"
             data-sal-duration="1000"
        >
          <img alt="" src={data.salesforceConsultingIcon.publicURL}/>
          <h3>Salesforce Consulting</h3>
          <p>The power of Salesforce lies in its flexibility to accommodate a miriad of use cases through customizations. Whether it's declarative or requires code - we have years of experience and are ready to guide you and work along side your operations or dev teams.</p>
          <Link to="#salesforce-consulting-second"><img alt="" className="arrow-link" src={data.salesforceConsultingNext.publicURL}/></Link>
        </div>
      </div>
    </div>
  )
}

export default SalesforceConsultingFirst
