import React from "react";
import "./full-stack-development.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const FullStackDevelopment = () => {
  const data = useStaticQuery(graphql`
    query {
      fullStackDevMain: file(relativePath: { eq: "full-stack-dev-main.png" }) {
        publicURL
      },
      fullStackDevYellowCircle: file(relativePath: { eq: "full-stack-dev-yellow-circle.svg" }) {
        publicURL
      },
      fullStackDevHalfCircle: file(relativePath: { eq: "full-stack-dev-half-circle.svg" }) {
        publicURL
      },
      fullStackDevTwoCircles: file(relativePath: { eq: "full-stack-dev-two-circles.svg" }) {
        publicURL
      },
      fullStackDevCurvyBg: file(relativePath: { eq: "full-stack-dev-curvy-bg.png" }) {
        publicURL
      },
      fullStackDevTwoTriangles: file(relativePath: { eq: "full-stack-dev-two-triangles.svg" }) {
        publicURL
      },
      fullStackDevYellowGrid: file(relativePath: { eq: "full-stack-dev-yellow-grid.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="salesforce-product-content">
      <div className="salesforce-product-content-left">
        <img alt="" className="content-left-img" src={data.fullStackDevMain.publicURL}/>
        <img alt="" className="content-left-yellow-circle" src={data.fullStackDevYellowCircle.publicURL}/>
        <img alt="" className="content-left-half-cirle" src={data.fullStackDevHalfCircle.publicURL}/>
        <img alt="" className="content-left-two-circles" src={data.fullStackDevTwoCircles.publicURL}/>
        <img alt="" className="content-left-curvy-bg" src={data.fullStackDevCurvyBg.publicURL}/>
        <img alt="" className="content-left-two-triangles" src={data.fullStackDevTwoTriangles.publicURL}/>
        <div className="content-left-yellow-circle-two"></div>
        <img alt="" className="content-left-yellow-grid" src={data.fullStackDevYellowGrid.publicURL}/>
      </div>
      <div className="salesforce-product-content-right">
        <h3 className="salesforce-product-content-title">Full Stack Development</h3>
        <p className="salesforce-product-content-text">While there are numerous use cases that can be addressed natively in Salesforce, sometimes it makes sense to think outside the platform. Integration with a 3rd party service can bring critical functionality to Salesforce and allow harnessing the power of any technology available. Our team has expertise in Python, NodeJs, Java and Ruby. We can also help you deploy your service internally, or host it on AWS, Azure or Google Cloud platforms.  
        </p>
        <Link to="/#contact" className="yellow-btn">Start Building</Link>
        {/* <button className="salesforce-product-content-btn">
              <img className="salesforce-product-content-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-product-content-btn-text">Learn more</p>
            </button> */}
      </div>
    </div>
  )
};

export default FullStackDevelopment
