import React from "react";
import "./integrations.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const Integrations = () => {
  const data = useStaticQuery(graphql`
    query {
      designArchitectureMain: file(relativePath: { eq: "design-architecture-main.png" }) {
        publicURL
      },
      designArchitectureTwoCircles: file(relativePath: { eq: "design-architecture-two-circles.svg" }) {
        publicURL
      },
      designArchitectureCurvyBg: file(relativePath: { eq: "design-architecture-curvy-bg.png" }) {
        publicURL
      },
      designArchitectureTrianglesCircle: file(relativePath: { eq: "design-architecture-triangles-circle.svg" }) {
        publicURL
      },
      designArchitectureGridImg: file(relativePath: { eq: "design-architecture-grid-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting-content-section">
      <div className="salesforce-consulting-content-section-left">
        <h3 className="salesforce-consulting-content-section-title">Integrations</h3>
        <p className="salesforce-consulting-content-section-text">Salesforce is a fantastic tool but it doesn't go very far without data. Data is the most valuable asset your company possess and it is the foundation of many business decisions. Moving data between systems is no easy task. Luckily, Salesforce has extensive integration capabilities that can be leveraged to make any data available at your fingertips. Our team can help you connect your external and internal systems and make the data flow seamlessly. 
        </p>
        <Link to="/#contact" className="yellow-btn">Start Integrating</Link>
        {/* <button className="salesforce-consulting-content-btn">
              <img alt="" className="salesforce-consulting-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-consulting-btn-text">Learn more</p>
            </button> */}
      </div>
      <div className="salesforce-consulting-content-section-right">
        <img alt="" className="design-architecture-main" src={data.designArchitectureMain.publicURL}/>
        <div className="design-architerture-little-circle"></div>
        <img alt="" className="design-architerture-two-circles" src={data.designArchitectureTwoCircles.publicURL}/>
        <div className="design-architerture-yellow-border"></div>
        <img alt="" className="design-architerture-curvy-bg" src={data.designArchitectureCurvyBg.publicURL}/>
        <img alt="" className="design-architerture-triangles-circle" src={data.designArchitectureTrianglesCircle.publicURL}/>
        <img alt="" className="design-architerture-grid-img" src={data.designArchitectureGridImg.publicURL}/>
        {/* <img alt="" src={data.designAndArchitectureImg.publicURL}/> */}
      </div>
    </div>
  )
};

export default Integrations
