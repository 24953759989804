import React from "react";
import "./community-implementations.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const CommunityImplementations = () => {
  const data = useStaticQuery(graphql`
    query {
      designArchitectureMain: file(relativePath: { eq: "design-architecture-main.png" }) {
        publicURL
      },
      designArchitectureTwoCircles: file(relativePath: { eq: "design-architecture-two-circles.svg" }) {
        publicURL
      },
      designArchitectureCurvyBg: file(relativePath: { eq: "design-architecture-curvy-bg.png" }) {
        publicURL
      },
      designArchitectureTrianglesCircle: file(relativePath: { eq: "design-architecture-triangles-circle.svg" }) {
        publicURL
      },
      designArchitectureGridImg: file(relativePath: { eq: "design-architecture-grid-img.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="salesforce-consulting-content-section">
      <div className="salesforce-consulting-content-section-left">
        <h3 className="salesforce-consulting-content-section-title">Community Implementations</h3>
        <p className="salesforce-consulting-content-section-text">Salesforce Communities are a great way to host public facing content that feeds directly into your org without the need to for costly integrations. 
        Salesforce provides a great number of tools to build and customize communities. 
        However, with great power comes great responsibility. 
        It is not easy to navigate this abundance of functionality and choosing what's right for your company may be a difficult task. 
        Our team has built all flavors of Communities, from one-pager case forms to sophisticated ecommerce websites. 
        </p>
        <Link to="/#contact" className="yellow-btn">Start Building</Link>
        {/* <button className="salesforce-consulting-content-btn">
              <img alt="" className="salesforce-consulting-btn-img" src={data.yellowBtnBg.publicURL}/>
              <p className="salesforce-consulting-btn-text">Learn more</p>
            </button> */}
      </div>
      <div className="salesforce-consulting-content-section-right">
        <img alt="" className="design-architecture-main" src={data.designArchitectureMain.publicURL}/>
        <div className="design-architerture-little-circle"></div>
        <img alt="" className="design-architerture-two-circles" src={data.designArchitectureTwoCircles.publicURL}/>
        <div className="design-architerture-yellow-border"></div>
        <img alt="" className="design-architerture-curvy-bg" src={data.designArchitectureCurvyBg.publicURL}/>
        <img alt="" className="design-architerture-triangles-circle" src={data.designArchitectureTrianglesCircle.publicURL}/>
        <img alt="" className="design-architerture-grid-img" src={data.designArchitectureGridImg.publicURL}/>
        {/* <img src={data.designAndArchitectureImg.publicURL}/> */}
      </div>
    </div>
  )
};

export default CommunityImplementations
